import React, { useEffect, useReducer, useRef, memo, Fragment, useState, useContext } from "react";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Spin } from "antd";
import reducer from "../reducer";
import DroppablePalette from "../../../presentational/DroppablePalette";
import DraggableItem from "../../../presentational/DraggableItem";
import DragHandle from "../../../presentational/DragHandle";
import ConfirmDialog from "../../../presentational/ConfirmDialog";
import ConditionalDisplay from "../../../presentational/ConditionalDisplay";
import LearningUnitComponentBuilder from "../../LearningUnitComponentBuilder";
import LearningUnitComponentPreview from "../../LearningUnitComponentPreview";
import EditableText from "../../../presentational/EditableText";
import { PageContentRefContext } from "../../../pages/BuildLearningUnit";
import {
  openOutdatedNotification,
  openSaveErrorNotification,
  openSubmitForReviewErrorNotification,
  openPublishErrorNotification,
  openMCQwithNoAnswerNotification,
  openEmptyQuestionDescriptionNotification,
  openEmptyAssessmentNotification,
  openEmptyAnswerptionNotification,
  initState,
  saveSnapshot,
  submitSnapshotForReview,
  saveLearningUnitChanges,
  efficientLearningUnitFieldsUpdate,
  shouldBuilderBeReadOnly,
  setLearningUnitEditable,
  setLearningUnitPublished,
} from "./helpers";
import {
  reorder,
  add,
  togglePreviewMode,
  undo,
  redo,
  saveSuccess,
  saveOutdated,
  saveFailed,
  setReadOnly,
  submitForReviewSuccess,
  updateLearningUnit,
  editableSuccess,
  publishedSuccess,
  resetState,
  // toggleShowComments,
  // showComments,
} from "../actions";
import {
  PaletteContainer,
  EditableContainer,
  BuilderContainer,
  PreviewToggleButton,
  MenuBar,
  HiddenDivToRemoveInlineEditorFocusOnDrag,
  StickyContainer,
  StickyComponent,
  SaveStatusText,
  PageMessage,
  BuilderInnerContainer,
  LearningUnitContainer,
  SubmitForReviewButton,
  AddNewButton,
  UndoButton,
  RedoButton,
  ScrollToTopButton,
  ScrollToTopButtonFixed,
  Tips,
  Divide,
  EditButton,
  PublishButton,
  ShowCommentsButton,
  CharCount,
  FullScreenContainer,
  SlideShowControl,
  SlideShowPrevButton,
  SlideShowNextButton,
  SlideShowStartButton,
  FullScreenInnerContainer,
} from "./styled";
import { AUTOSAVE_STATUS } from "../../../../constants/types";
import AddNew from "../AddNew";
import {
  UPDATE_CONTENT_SNAPSHOT,
  UPDATE_CONTENT_SNAPSHOT_AND_LEARNING_UNIT,
  SUBMIT_LEARNING_UNIT_FOR_REVIEW,
  MAKE_LEARNING_UNIT_EDITABLE,
  PUBLISH_LEARNING_UNIT,
  UPDATE_SUBJECT_ASSESSMENT_DISPLAY_NAME,
} from "./mutations";
import { useDidUpdate, useNavigatorOnline } from "../../../../utils/hooks";
import { setLastSaveTimestampFor } from "../../../../utils/sessionUtils";
import { generateJitterIntervals } from "../../../../utils/helpers";

// const { SubMenu } = Menu;

const AUTOSAVE_RETRY_AVERAGE_INTERVAL = 10000;
const LUC_LIMIT_SIZE = 50;
const maxLength = 100;

const Builder = ({
  learningUnit,
  learningUnitId,
  tutorialId,
  subjectId,
  version,
  collapsedMenu,
  refetchToC,
  setLastModifiedBy,
  refetchVersionMenu,
  showComments,
  toggleShowComments,
  setSelectedVersion,
}) => {
  if (!learningUnit) return <></>;

  const history = useHistory();
  // shared prop object for <Tips> component
  const tipProps = { placement: collapsedMenu ? "right" : "left" };

  // _______________hooks_________________

  useEffect(() => setLastSaveTimestampFor(learningUnitId, learningUnit.lastSaveTimeStamp), [learningUnitId]);
  const autoSaveInterval = useRef(null);

  const [state, dispatch] = useReducer(reducer, learningUnit, initState);

  const {
    learningUnitComponents,
    isPreviewMode,
    isReadOnly,
    undoStack,
    redoStack,
    snapshotSaveStatusText,
    learningUnitFields,
    isSubmittedForReview,
    isPublished,
    isArchived,
    saveStatus,
    accessLevel,
    // isShowComments,
  } = state;

  const [currentLucIndex, setCurrentLucIndex] = useState(0);
  const [isSlideShow, setIsSlideShow] = useState(false);
  const contentRef = useRef(null);

  const requestFullScreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE/Edge */
      element.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  const toggleSlideShow = () => {
    setIsSlideShow(!isSlideShow);
    setCurrentLucIndex(0);
    if (!isSlideShow && contentRef.current) {
      requestFullScreen(contentRef.current);
    }
  };

  // handles users exiting the slide show
  const handleSlideShowExit = () => {
    if (isSlideShow && !document.fullscreenElement) {
      toggleSlideShow();
    }
  };

  const nextLuc = () => {
    setCurrentLucIndex((prevIndex) => Math.min(prevIndex + 1, learningUnitComponents.size - 1));
  };

  const prevLuc = () => {
    setCurrentLucIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const [title, setTitle] = useState(get(learningUnit, "subjectAssessments.0.displayName") || learningUnit.name || "Untitled");
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const subjectAssessmentId = get(learningUnit, "subjectAssessments.0._id", null);

  const lucHasNoSuggestions = (luc) => !luc.suggestions || luc.suggestions === "[]";
  const lucHasNoComments = (luc) => !luc.comments || luc.comments === "[]";

  const isOverLimit = learningUnitComponents.size >= LUC_LIMIT_SIZE;
  const hasSuggestions = learningUnitComponents.filter((luc) => !lucHasNoSuggestions(luc)).size !== 0;
  const hasComments = learningUnitComponents.filter((luc) => !lucHasNoComments(luc)).size !== 0;

  const { isOffline } = useNavigatorOnline();
  // we want the builder to be read only when app is offline
  useEffect(() => {
    const readOnlyState = isOffline
      ? true
      : shouldBuilderBeReadOnly({ isSubmittedForReview, isPublished, isArchived, saveStatus, accessLevel });
    dispatch(setReadOnly(readOnlyState));
  }, [isOffline]);

  // use arrow keys to navigate through slideshow
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isSlideShow) {
        if (e.keyCode === 37) {
          // left arrow
          prevLuc();
        } else if (e.keyCode === 39) {
          // right arrow
          nextLuc();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("fullscreenchange", handleSlideShowExit);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("fullscreenchange", handleSlideShowExit);
    };
  }, [isSlideShow]);

  const [updateSubjectAssessmentDisplayName] = useMutation(UPDATE_SUBJECT_ASSESSMENT_DISPLAY_NAME, {
    onCompleted: (data) => {
      setTitle(data.updateSubjectAssessmentDisplayName.displayName);
      refetchToC.refetch();
    },
  });

  const [makeLearningUnitEditable, { loading: beingMadeEditable }] = useMutation(MAKE_LEARNING_UNIT_EDITABLE, {
    onCompleted: (data) => {
      if (data.makeLearningUnitEditable.saveStatus === AUTOSAVE_STATUS.OUTDATED) {
        openOutdatedNotification(() => window.location.reload());
      } else {
        dispatch(editableSuccess());
      }
    },
  });

  const [publishLearningUnit, { loading: beingPublished }] = useMutation(PUBLISH_LEARNING_UNIT, {
    onCompleted: (data) => {
      if (data.publishLearningUnit.saveStatus === AUTOSAVE_STATUS.OUTDATED) {
        openOutdatedNotification(() => window.location.reload());
      } else {
        dispatch(publishedSuccess());
        refetchVersionMenu.refetch();

        const versionId = get(data, "publishLearningUnit.currentVersionSyncId", "");
        const url = `/sbj${subjectId}/tut${tutorialId}/buildlearningunit${learningUnitId}/v${versionId}`;
        history.push(url);
        setSelectedVersion(versionId);
      }
    },
    onError: (saveError) => {
      // NOTE: THIS IS A TEMPORARY SOLUTION TO THE ERROR NOTIFICATION POPPING UP ON A 503 ERROR.
      // NOT A GOOD OR PERMANENT SOLUTION.
      if (saveError.response.status !== 503) {
        openPublishErrorNotification("err-publish-notify-key");
      }
    },
  });

  const [submitLearningUnitForReview, { loading: isSubmittingForReview }] = useMutation(SUBMIT_LEARNING_UNIT_FOR_REVIEW, {
    onCompleted: (saveData) => {
      clearTimeout(autoSaveInterval.current);

      if (saveData.submitLearningUnitForReview.saveStatus === AUTOSAVE_STATUS.SUCCESS) {
        dispatch(submitForReviewSuccess());
        setLastSaveTimestampFor(learningUnitId, saveData.submitLearningUnitForReview.lastSaveTimeStamp);
        // submit learning unit for review is a save so we store it's time stamp
      }

      if (
        saveData.submitLearningUnitForReview.saveStatus === AUTOSAVE_STATUS.OUTDATED ||
        saveData.submitLearningUnitForReview.saveStatus === AUTOSAVE_STATUS.ANOTHER_USER_IS_EDITING ||
        saveData.submitLearningUnitForReview.saveStatus === AUTOSAVE_STATUS.ARCHIVED
      ) {
        dispatch(saveOutdated());
        openOutdatedNotification(() => window.location.reload());
      }
    },
    onError: (saveError) => {
      openSubmitForReviewErrorNotification("err-sub-review-notify-key");
    },
  });

  const [updateContentSnapshot] = useMutation(UPDATE_CONTENT_SNAPSHOT, {
    onCompleted: (saveData) => {
      clearTimeout(autoSaveInterval.current);
      if (saveData.updateContentSnapshot.saveStatus === AUTOSAVE_STATUS.SUCCESS) {
        dispatch(saveSuccess()); // update session with corrcet last autosave
        const snap = saveData.updateContentSnapshot;
        setLastSaveTimestampFor(learningUnitId, snap.lastSaveTimeStamp);
        setLastModifiedBy({ name: snap.lastModifiedByContact.name, timestamp: snap.lastSnapshotSaveTimeStamp });
      }
      if (
        saveData.updateContentSnapshot.saveStatus === AUTOSAVE_STATUS.OUTDATED ||
        saveData.updateContentSnapshot.saveStatus === AUTOSAVE_STATUS.ANOTHER_USER_IS_EDITING ||
        saveData.updateContentSnapshot.saveStatus === AUTOSAVE_STATUS.ARCHIVED
      ) {
        dispatch(saveOutdated());
        openOutdatedNotification(() => window.location.reload());
      }
    },
    onError: (saveError) => {
      clearTimeout(autoSaveInterval.current);
      const saveSnapshotThunk = () =>
        saveSnapshot(dispatch, learningUnitComponents, learningUnitFields, learningUnitId, tutorialId, updateContentSnapshot);
      const delay = generateJitterIntervals(AUTOSAVE_RETRY_AVERAGE_INTERVAL);
      autoSaveInterval.current = setTimeout(saveSnapshotThunk, delay);
      dispatch(saveFailed());
      openSaveErrorNotification(saveSnapshotThunk, "err-save-notify-key");
    },
  });

  const [updateContentSnapshotAndLearningUnit] = useMutation(UPDATE_CONTENT_SNAPSHOT_AND_LEARNING_UNIT, {
    onCompleted: (saveData) => {
      if (saveData.updateContentSnapshotAndLearningUnit.saveStatus === AUTOSAVE_STATUS.SUCCESS) {
        dispatch(saveSuccess()); // update session with corrcet last autosave
        const snap = saveData.updateContentSnapshotAndLearningUnit;
        setLastSaveTimestampFor(learningUnitId, snap.lastSaveTimeStamp);
        setLastModifiedBy({ name: snap.lastModifiedByContact.name, timestamp: snap.lastSnapshotSaveTimeStamp });
      }
      if (saveData.updateContentSnapshotAndLearningUnit.saveStatus === AUTOSAVE_STATUS.OUTDATED) {
        dispatch(saveOutdated());
        openOutdatedNotification(() => window.location.reload());
      }
    },
  });

  useDidUpdate(() => {
    clearTimeout(autoSaveInterval.current);
    if (!isReadOnly) saveSnapshot(dispatch, state.learningUnitComponents, learningUnit, learningUnitId, tutorialId, updateContentSnapshot);
  }, [state.learningUnitComponents]);

  useDidUpdate(() => {
    // currently we only allow the user to save changes to learning unit name we may incorporate changing learning unit types in the future
    const { name } = learningUnitFields;
    if (!isReadOnly) saveLearningUnitChanges(dispatch, { name }, learningUnitId, tutorialId, updateContentSnapshotAndLearningUnit);
  }, [state.learningUnitFields.name]);

  // useDidUpdate(() => {
  //   // currently we only allow the user to save changes to learning unit name we may incorporate changing learning unit types in the future
  //   dispatch(resetState(learningUnit));
  // }, [version]);

  const showPreviewOnly = isPreviewMode || isReadOnly;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    dispatch(reorder(result.source.index, result.destination.index));
  };
  /* 
  The hidden div is used to blur the inline editor so that the toolbar disappears when dragging. 
  The reason we resort to this hack is that the official CKEditor wrapper (@ckeditor/ckeditor5-react)
  does not provide a way to programmatically blur the editor. The work around used here relies on the 
  fact at only one element can be focused on at a time hince by focusing on the hidden div we blur the editor
  */
  const hiddenDivRef = useRef(null);
  const onDragStart = () => {
    hiddenDivRef.current.focus();
  };

  const renderTitle = () => {
    if (isOverLimit)
      return (
        <Tips {...tipProps} title="You've reached max size!">
          <AddNewButton disabled={isOverLimit} />
        </Tips>
      );
    return <AddNewButton disabled={isOverLimit} />;
  };

  // Rendered in edit mode only
  const renderUtils = () => {
    const pageContentRef = useContext(PageContentRefContext);
    return (
      <React.Fragment>
        <Divide />
        <AddNew title={renderTitle()} isOverLimit={isOverLimit} dispatch={dispatch} />
        <Tips {...tipProps} title="Undo">
          <UndoButton onClick={() => undoStack.size > 0 && dispatch(undo())} disabled={undoStack.size === 0} />
        </Tips>
        <Tips {...tipProps} title="Redo">
          <RedoButton onClick={() => redoStack.size > 0 && dispatch(redo())} disabled={redoStack.size === 0} />
        </Tips>
        <Tips {...tipProps} title="Scroll to top">
          <ScrollToTopButton
            onClick={() => {
              if (pageContentRef.current) pageContentRef.current.scrollTop = 0;
            }}
          />
        </Tips>
        <Tips {...tipProps} title={!showComments ? "Show Comments" : "Hide Comments"}>
          <ShowCommentsButton ghost={!showComments} onClick={() => toggleShowComments()} />
        </Tips>
        <SaveStatusText>{snapshotSaveStatusText}</SaveStatusText>
      </React.Fragment>
    );
  };

  // check if there is any MCQ without correct answer
  const hasMCQWithoutCorrectAnswer = learningUnitComponents.some((luc) => {
    if (luc.questions && luc.questions.size > 0) {
      return luc.questions.some((q) => {
        if (q.type === "Multiple choice") {
          try {
            const correctAnswerIds = JSON.parse(q.correctAnswerValue);
            if (q.answerOptions && q.answerOptions.size < 2) return true;

            // check whether there is any correct answer
            return !q.answerOptions.some((option) => option.correctValue);
          } catch (e) {
            // if parsing fails which means there is new question just added with empty correctAnswerValue,
            // we return true
            return true;
          }
        }
        return false;
      });
    }
    return false;
  });

  const hasEmptyQuestionDescription = learningUnitComponents.some((luc) => {
    if (luc.type === "Assessment Opportunity") {
      if (luc.questions && luc.questions.size > 0) {
        return luc.questions.some((q) => {
          if (!q.question && q.question == "") {
            return true;
          }
          return false;
        });
      }
    }
    return false;
  });

  const hasEmptyAnswerOption = learningUnitComponents.some((luc) => {
    if (luc.type === "Assessment Opportunity") {
      if (luc.questions && luc.questions.size > 0) {
        return luc.questions.some((q) => {
          if (q.type !== "Free Text") {
            return q.answerOptions.some((option) => {
              if (option.value === "") {
                return true;
              }
            });
          }
          return false;
        });
      }
    }
    return false;
  });

  const hasEmptyAssessment = learningUnitComponents.some((luc) => {
    if (luc.type === "Assessment Opportunity") {
      if (!luc.questions || luc.questions.size === 0) {
        return true;
      }
    }
    return false;
  });

  const renderMenuBar = () => (
    <MenuBar collapsedMenu={collapsedMenu} preview={showPreviewOnly}>
      <ConfirmDialog
        placement="right"
        title="Are you sure?"
        onConfirm={() => {
          if (hasMCQWithoutCorrectAnswer) {
            // alert("Please ensure all Multiple Choice Questions have an answer selected before submitting for review.");
            openMCQwithNoAnswerNotification("err-mcq-notify-key");
          } else if (hasEmptyAssessment) {
            openEmptyAssessmentNotification("err-empty-assessment-notify-key");
          } else if (hasEmptyQuestionDescription) {
            openEmptyQuestionDescriptionNotification("err-empty-question-description-notify-key");
          } else if (hasEmptyAnswerOption) {
            openEmptyAnswerptionNotification("err-empty-answer-option-notify-key");
          } else {
            submitSnapshotForReview(learningUnitId, tutorialId, submitLearningUnitForReview);
          }
        }}
      >
        <Tips {...tipProps} title="Submit for Review">
          <SubmitForReviewButton loading={isSubmittingForReview} disabled={isSubmittedForReview || isPublished} />
        </Tips>
      </ConfirmDialog>
      <Tips {...tipProps} title={isPreviewMode ? "Edit" : "Preview"}>
        <PreviewToggleButton icon={isPreviewMode ? "form" : "monitor"} onClick={() => dispatch(togglePreviewMode())} />
      </Tips>

      {!showPreviewOnly && renderUtils()}
    </MenuBar>
  );

  const onEditorCommentToolbarClick = () => {
    toggleShowComments(true);
  };

  const handleTitleInputChange = (value) => {
    if (value.length <= maxLength) {
      setTitle(value);
    }
  };

  const handleTitleInputFocus = (editing) => {
    setIsTitleEditing(editing);
  };

  const pageContentRef = useContext(PageContentRefContext);

  return (
    <BuilderContainer ref={contentRef}>
      <BuilderInnerContainer collapsedMenu={collapsedMenu} showComments={showComments}>
        {!isReadOnly && (
          <StickyContainer>
            <StickyComponent>{renderMenuBar()}</StickyComponent>
          </StickyContainer>
        )}

        <LearningUnitContainer collapsedMenu={collapsedMenu}>
          <ScrollToTopButtonFixed
            onClick={() => {
              if (pageContentRef.current) pageContentRef.current.scrollTop = 0;
            }}
          />
          {isPublished && <PageMessage>This learning unit has been published.</PageMessage>}
          {!isPublished && isSubmittedForReview && (
            <PageMessage>
              {beingPublished ? <Spin size="small" style={{ paddingRight: "15px" }} /> : ""}
              {beingPublished ? "This learning unit is being published." : "This learning unit has been submitted for review."}
              <br />
              {hasSuggestions || hasComments ? "Please resolve any comments or suggestions before publishing" : ""}
              {hasSuggestions || hasComments ? <br /> : ""}
              <EditButton
                disabled={beingPublished}
                onClick={() => setLearningUnitEditable(learningUnitId, tutorialId, makeLearningUnitEditable)}
              >
                EDIT
              </EditButton>
              <ConfirmDialog
                disabled={beingPublished || hasSuggestions || hasComments}
                placement="bottom"
                title="Further changes cannot be made. Are you sure you want to publish?"
                onConfirm={() => setLearningUnitPublished(learningUnitId, tutorialId, publishLearningUnit)}
              >
                <PublishButton disabled={beingPublished || hasSuggestions || hasComments}>PUBLISH</PublishButton>
              </ConfirmDialog>
            </PageMessage>
          )}
          {isArchived && <PageMessage>This learning unit has been archived. You can't make any changes.</PageMessage>}
          {learningUnit.saveStatus === AUTOSAVE_STATUS.ANOTHER_USER_IS_EDITING && (
            <PageMessage>Another user is currently making changes. Try again at a later time.</PageMessage>
          )}
          <EditableContainer>
            <EditableText
              value={title}
              onChange={handleTitleInputChange}
              isEditable={!showPreviewOnly}
              onEditStateChange={handleTitleInputFocus}
              onBlur={(str) =>
                updateSubjectAssessmentDisplayName({
                  variables: {
                    subjectAssessmentId,
                    tutorialId,
                    displayName: str,
                  },
                })
              }
            />
            {isTitleEditing && (
              <CharCount>
                {title.length}/{maxLength}
              </CharCount>
            )}
          </EditableContainer>
          <HiddenDivToRemoveInlineEditorFocusOnDrag ref={hiddenDivRef} />
          {isPublished && !isSlideShow && <SlideShowStartButton onClick={toggleSlideShow}></SlideShowStartButton>}
          {isPublished && isSlideShow && (
            <SlideShowControl>
              <SlideShowPrevButton onClick={prevLuc}></SlideShowPrevButton>
              <SlideShowNextButton onClick={nextLuc}></SlideShowNextButton>
            </SlideShowControl>
          )}
          <PaletteContainer collapsedMenu={collapsedMenu} showComments={showComments}>
            {isSlideShow ? (
              <FullScreenContainer>
                <FullScreenInnerContainer>
                  <LearningUnitComponentPreview
                    key={learningUnitComponents.get(currentLucIndex)._id}
                    luc={learningUnitComponents.get(currentLucIndex)}
                  />
                </FullScreenInnerContainer>
              </FullScreenContainer>
            ) : showPreviewOnly ? (
              learningUnitComponents.map((luc) => <LearningUnitComponentPreview key={luc._id} luc={luc} />)
            ) : (
              <ConditionalDisplay showIf={!showPreviewOnly}>
                <DroppablePalette onDragEnd={onDragEnd} onDragStart={onDragStart}>
                  {learningUnitComponents.map((luc, index) => (
                    <DraggableItem key={luc._id} draggableId={luc._id} index={index} dragHandle={DragHandle}>
                      <LearningUnitComponentBuilder
                        learningUnitId={learningUnitId}
                        tutorialId={tutorialId}
                        luc={luc}
                        dispatch={dispatch}
                        index={index}
                        showComments={showComments}
                        onEditorCommentToolbarClick={onEditorCommentToolbarClick}
                      />
                    </DraggableItem>
                  ))}
                </DroppablePalette>
              </ConditionalDisplay>
            )}
          </PaletteContainer>
        </LearningUnitContainer>
      </BuilderInnerContainer>
    </BuilderContainer>
  );
};

Builder.propTypes = {
  learningUnit: PropTypes.object.isRequired,
  learningUnitId: PropTypes.string.isRequired,
  tutorialId: PropTypes.string.isRequired,
  collapsedMenu: PropTypes.bool.isRequired,
};

export default memo(Builder);
